.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(to bottom, #4b5356 0%, #333 100%);
  color: #fff;
  height: 60px;

  .navbar {
    width: 100%;
    display: flex;
    align-items: center;

    .navbar-toggler {
      font-size: 25px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      background: linear-gradient(to bottom, #333, rgba(51, 51, 51, 0.4));
      position: absolute;
      top: 60px;
      width: 100%;
      left: 0;
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

      &.collapse {
        opacity: 1;
        max-height: 300px; // Adjust based on content
      }

      .nav-link {
        color: #fff;
        text-align: center;
        font-size: x-large;
        padding: 10px 20px;
        text-decoration: none;
        user-select: none;

        &:hover {
          text-decoration: underline;
          color: #00bfff;
        }
      }

      .resume-button {
        color: #fff;
        text-align: center;
        font-size: x-large;
        padding: 10px 20px;
        background: none;
        border: none;
        cursor: pointer;
        user-select: none;
  
        &:hover {
          text-decoration: underline;
          color: #00bfff;
        }
      }
    }

    .icon-links {
      display: flex;
      align-items: center;
      margin-left: auto; // Pushes the icons to the right
      margin-right: 40px;


      .blog-link {
        color: #fff;
        text-align: center;
        font-size: x-large;
        padding: 10px 5px;
        text-decoration: none;
        user-select: none;

        &:hover {
          text-decoration: underline;
          color: #00bfff;
        }
      }

      .icon-link {
        color: white;
        display: flex;
        font-size: 40px;
        margin-left: 20px; // Spacing between icon links

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          color: #00bfff;
        }
      }
    }

    @media (min-width: 1080px) {
      .navbar-toggler {
        display: none;
      }

      .nav-links {
        display: flex;
        flex-direction: row;
        position: static;
        align-items: center;
        background: transparent;
        overflow: visible;
        max-height: none; // Override max-height
        opacity: 1; // Ensure full visibility

        .nav-link {
          margin-right: 20px;
          opacity: 1; // Always fully visible on larger screens
          transform: translateY(0); // Back to normal position
          transition: none; // No transition on larger screens
        }
      }
    }
  }
}