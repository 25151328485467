.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

.contact-form {
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // margin: 20px;
  background-color: #333;
  color: white;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-inputs {

      .input-row {
        display: flex;
        justify-content: center;
        gap: 10px;
        width: fit-content;
      }

      .input-group {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 5px;
        }

        input,
        textarea {
          font-size: 16px;
          padding: 8px;
          margin-bottom: 20px; // Space below each input group
        }

        textarea {
          width: calc(100% - 19px);
          height: 75px;
          resize: vertical;
          min-height: 75px;
          max-height: 300px; // Adjust this as needed
        }

        small {
          display: block; // Ensures it takes its own line
          text-align: right; // Aligns the text to the right
          margin-top: -15px; // Adjusts spacing between the character count and the textarea
          margin-right: 5px; // Adjusts spacing from the right edge of the textarea
          color: #666; // Sets a more subdued color for the character count
          font-size: 0.8em; // Optionally reduce the font size of the counter
        }
      }
    }

    button {
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media (max-width: 600px) {
  .contact-form form .input-row {
    flex-direction: column;
    align-items: stretch; // Make children fill the width

    .input-group {
      margin: 0; // Reset margins for stacked layout
    }
  }
}
