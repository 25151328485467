.projects {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh; // Ensure the container takes minimum full viewport height
  background-size: cover; // Ensure the background covers the entire container
  background-position: center; // Center the background image
  background-repeat: no-repeat; // Prevent the background image from repeating
  text-align: center;
}

.title {
  justify-self: center;
  align-self: center;
}

.projects-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.project {
  display: flex;
  width: 45%;
  max-width: 50vw; // Restricts project width to viewport width
  overflow: hidden; // Ensures content beyond the viewport width is not visible
  background-color: rgba(
    249,
    249,
    249,
    0.9
  ); // Slightly transparent to show background
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  &:nth-child(odd) {
    flex-direction: row-reverse;
    border-radius: 0 10px 10px 0;
  }
  
  &:nth-child(even) {
    flex-direction: row;
    border-radius: 10px 0 0 10px;
    align-self: flex-end;
  }

  .project-description,
  .project-image {
    flex-basis: 50%; // Each takes up to 50% of the project container
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .project-description {
    z-index: 2; // Ensures text is above the image if they overlap
  }

  .project-image {
    max-width: 25vw; // Image width up to 50% of the viewport width
    img {
      width: 100%; // Image fills the container width
      height: auto; // Maintains aspect ratio
    }
  }
}

@media (max-width: 1080px) {
  .projects-container {
    align-items: center; // Center align all projects within the container
    max-width: 100%;
  }

  .project {
    width: 80%; // Use more of the screen's width to display projects
    max-width: 80%; // Consistency with width setting
    margin: 10px auto; // Centers the project in the container
    flex-direction: column; // Stacks image and description vertically
    align-items: center; // Ensures content alignment is centered within each project
    justify-content: center;
    
    .project-description,
    .project-image {
      padding: 10px;
      max-width: 100%; // Allows each component to fill the available space
      width: auto; // Ensures that the content fills the full width of the project box
      justify-content: center; // Center align the text content horizontally
    }
    
    .project-image {
      order: 0; // Image appears above the text
      padding-top: 20px;
    }
    
    .project-description {
      order: 1;
      padding-bottom: 20px;
    }
    
    &:nth-child(n) {
      flex-direction: column;
      border-radius: 10px;
    }
  }
}
