.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 80%;
    max-width: 1100px;
    height: 90%;
    position: relative;

    .close {
      position: absolute;
      top: -10px;
      right: 0px;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
    }

    .resume-preview {
      width: 100%;
      height: 95%;
      border: none;
    }

    .download-modal-button {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #218838;
      }
    }
  }
}
