.about-me-container {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center; // Ensures all content is aligned to the center
  max-width: 100vw; // Ensure the container takes the full viewport width
  min-height: 100vh; // Ensure the container takes minimum full viewport height
  // background-image: url('/slanted-gradient.svg'); // Moved here for clarity, adjust as needed
  background-size: cover; // Ensure the background covers the entire container
  background-position: center; // Center the background image
  background-repeat: no-repeat; // Prevent the background image from repeating
  text-align: center;
  overflow: hidden;
}

.about-me-section {
  width: 80%; // Adjusted for demonstration, change based on your design
  display: flex;
  justify-content: center; // Center the content by default
  margin: 40px 0; // Increase vertical spacing between sections
  z-index: 2; // Ensure text is above the background

  p {
    max-width: 38%; // Adjust based on your design needs
    line-height: 1.6; // Improves readability
    padding: 20px; // Add padding for better spacing and readability
    background-color: rgba(
      249,
      249,
      249,
      0.9
    ); // Slightly transparent to show background
    border-radius: 10px; // Optional: adds rounded corners for stylistic effect
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow for depth
    position: relative; // Needed for z-index to work correctly
  }

  &.left p {
    margin: 0 auto 0 8rem; // Adjust based on your design
  }

  &.right p {
    margin: 0 8rem 0 auto; // Adjust based on your design
  }
}

@media (max-width: 1080px) {
  .about-me-container {
    padding: 20px;
  }

  .about-me-section {
    width: 100%; // Use full width to allow more space for content
    max-width: 100%;
    margin: 20px 0; // Adjust vertical spacing

    &.left p {
      text-align: center;
      margin: 0 auto 0 10rem; // Adjust based on your design
    }

    &.right p {
      text-align: center;
      margin: 0 10rem 0 auto; // Adjust based on your design
    }
  }

  .about-me-section p {
    text-align: justify; // Improved text alignment for readability
    margin: 20px auto; // Center paragraphs by setting equal horizontal margins
    max-width: 90%; // Increase width to utilize more screen space
  }

  .about-me-section.left p,
  .about-me-section.right p {
    margin-left: auto;  // Center alignment for left/right specific paragraphs
    margin-right: auto; // Center alignment for left/right specific paragraphs
  }

}